import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import useTranslation from 'next-translate/useTranslation';

const Title = ({ promoType }: { promoType: string }) => {
  const { t } = useTranslation('home');

  const subHeading = t(`${promoType}.sectionSubHeading`);
  return (
    <React.Fragment>
      <h2
        className={ivclass(
          'iv-text-grey-85',
          'xl:iv-text-heading-4xl iv-text-heading-2xl sm:iv-text-heading-2xs',
          'xl:iv-m-heading-4xl iv-m-heading-2xl sm:iv-m-heading-2xs',
          'iv-font-extrabold',
        )}
      >
        {t(`${promoType}.sectionHeading`)}
      </h2>
      {subHeading ? (
        <h5
          className={ivclass(
            'iv-text-grey-85',
            'iv-block iv-pt-20',
            'xl:iv-text-heading-4xl iv-text-heading-2xl sm:iv-text-heading-2xs',
            'xl:iv-m-heading-4xl iv-m-heading-2xl sm:iv-m-heading-2xs',
            'iv-leading-tight',
            'iv-font-extrabold',
            'iv-title-gradient',
          )}
        >
          {subHeading}
        </h5>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default Title;
