import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import Title from '@components/app-promo/Title';
import Details from '@components/app-promo/Details';
import { HOMEPAGE } from '../../constants/homepage';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import useTranslation from 'next-translate/useTranslation';
import AppPromoAI from './AppPromoAI';

interface AppPromoProps {
  os: string;
  isNotDesktop: boolean;
  promoType?: string;
  isPreview?: boolean;
}

const AppPromo = (props: AppPromoProps) => {
  const { os, isNotDesktop, promoType = 'appPromo', isPreview } = props;
  const { mobileImage } = HOMEPAGE.appPromo;
  const { t } = useTranslation('home');

  return promoType == 'appPromoAI' ? (
    <AppPromoAI {...props} />
  ) : (
    <section
      className={ivclass(
        'xl:iv-mb-[-3px] lg:iv-mb-[-3px]',
        'xl:iv-pb-150 lg:iv-pb-150',
        'xl:iv-px-20 lg:iv-px-40 md:iv-px-25 sm:iv-px-20',
        'xl:iv-pt-200 iv-pt-150 sm:iv-pt-100',
      )}
    >
      <div
        className={ivclass(
          'xl:iv-mb-[220px] lg:iv-mb-[180px] md:iv-mb-200 sm:iv-mb-[130px]',
        )}
      >
        <div
          className={ivclass(
            'iv-flex iv-flex-col iv-justify-center iv-items-center',
          )}
        >
          <Title promoType={promoType} />
        </div>
      </div>
      <div
        className={ivclass(
          'iv-gradient-raspberry-red xl:iv-rounded-30 iv-rounded-25 iv-min-h-[560px]',
        )}
      >
        <div
          className={ivclass(
            'iv-grid',
            'iv-gap-40',
            'iv-grid-cols-3 md:iv-grid-cols-1 sm:iv-grid-cols-1',
          )}
        >
          <div
            className={ivclass(
              'md:iv-order-2 sm:iv-order-2',
              'iv-place-self-center',
            )}
          >
            <div
              className={ivclass(
                'xl:iv-pl-50 lg:iv-pl-50',
                'iv-text-grey-0',
                'iv-text-center',
                'iv-text-heading-4xl sm:iv-text-heading-s',
                'iv-m-heading-4xl sm:iv-m-heading-s',
                'iv-font-extrabold',
              )}
            >
              {t(`${promoType}.cardHeading`)}
            </div>
          </div>
          <div
            className={ivclass('iv-relative', 'md:iv-order-1 sm:iv-order-1')}
          >
            <div
              className={ivclass(
                'xl:iv-absolute lg:iv-absolute',
                'xl:iv-top-[-145px] lg:iv-top-[-130px] iv-left-0',
                'iv-w-full',
                'xl:iv-px-10 lg:iv-px-[12px]',
                'md:iv-mt-[-150px] sm:iv-mt-[-80px]',
                'md:iv-mb-20 sm:iv-mb-2',
                'md:iv-mx-auto sm:iv-mx-auto',
                'md:iv-max-w-[379px] sm:iv-max-w-[220px]',
              )}
            >
              <ImageComponent
                elementype="next"
                src={mobileImage}
                alt="InVideo Logo"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
                sizes="100vw"
                width={421}
                height={852}
              />
            </div>
          </div>
          <div
            className={ivclass(
              'md:iv-px-100',
              'md:iv-order-3 sm:iv-order-3',
              'iv-place-self-center',
            )}
          >
            <Details
              os={os}
              isNotDesktop={isNotDesktop}
              promoType={promoType}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppPromo;
