import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

interface AppDownloadsCount {
  count: string;
  text: string;
}

const AppDownloadsCount = (props: AppDownloadsCount) => {
  const { count, text } = props;
  return (
    <div>
      <div
        className={ivclass(
          'iv-text-grey-0',
          'iv-text-heading-m sm:iv-text-heading-2xs',
          'iv-m-heading-m',
          'iv-font-extrabold',
          'iv-pb-8 sm:iv-pb-5',
          'sm:iv-text-center',
        )}
      >
        {count}
      </div>
      <div
        className={ivclass(
          'iv-text-grey-0',
          'iv-text-body-l sm:iv-text-body-s',
          'iv-m-body-l sm:iv-m-body-s',
          'iv-font-semibold',
        )}
      >
        {text}
      </div>
    </div>
  );
};

export default AppDownloadsCount;
