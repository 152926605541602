import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import AppDownloadsCount from '@components/app-promo/AppDownloadsCount';
import { HOMEPAGE } from '../../constants/homepage';
import AppDownloadCTAs from '@components/app-promo/AppDownloadCTAs';
import GlobalConfig from '@invideoio/web-shared/lib/GlobalConfig';
import { OSType } from '@invideoio/web-shared/constants/common';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import useTranslation from 'next-translate/useTranslation';

interface DetailsProps {
  os: string;
  isNotDesktop: boolean;
  promoType?: string;
}

const Details = (props: DetailsProps) => {
  const { os: OS, isNotDesktop, promoType } = props;

  const { achievement } = HOMEPAGE.appPromo;
  const { t } = useTranslation('home');

  const isIOS = OS === OSType.iOS;
  const isAndroid = OS === OSType.Android;
  const isMobile = isIOS || isAndroid;

  return (
    <div
      className={ivclass(
        promoType !== 'appPromoAI' && 'xl:iv-py-[86px] lg:iv-py-60',
        'md:iv-pt-[18px] md:iv-pb-[56px]',
      )}
    >
      {promoType !== 'appPromoAI' && (
        <div
          className={ivclass(
            'iv-flex iv-flex-wrap',
            'md:iv-justify-center sm:iv-justify-center',
          )}
        >
          <div
            className={ivclass(
              'iv-mr-40 md:iv-mr-[48px] sm:iv-mr-40',
              'iv-w-[108px] sm:iv-w-[83px]',
              'sm:iv-h-[46px]',
            )}
          >
            <ImageComponent
              elementype="native"
              width={108}
              height={60}
              alt="Best of 2019"
              src={achievement}
              loading="lazy"
            />
          </div>
          <AppDownloadsCount
            count={t(`${promoType}.downloads.appDownloadsCount`)}
            text={t(`${promoType}.downloads.subText`)}
          />
        </div>
      )}

      <div
        className={ivclass(
          'xl:iv-pb-35 lg:iv-pb-35 md:iv-pb-35',
          'xl:iv-pt-40 lg:iv-pt-40 md:iv-pt-[46px] md:iv-pb-35',
          'xl:iv-pr-50 lg:iv-pr-[83px]',
          'sm:iv-py-[34px] sm:iv-px-15',
          'md:iv-text-center sm:iv-text-center',
          promoType !== 'appPromoAI' && 'iv-text-left',
        )}
      >
        <div
          className={ivclass(
            'iv-text-grey-0',
            'xl:iv-text-heading-3xs iv-text-heading-4xs sm:iv-text-body-2xl',
            'xl:iv-m-heading-3xs iv-m-heading-4xs sm:iv-m-body-2xl',
            'iv-font-semibold',
            promoType === 'appPromoAI' && 'iv-text-white-50',
          )}
        >
          {t(`${promoType}.description.highlightedText`)}
          <span
            className={ivclass(
              promoType !== 'appPromoAI'
                ? 'iv-text-white-60'
                : 'iv-block iv-text-white-50',
            )}
          >
            {t(`${promoType}.description.plainText`)}
          </span>
        </div>
      </div>
      <div
        className={ivclass(
          'iv-flex iv-flex-row iv-justify-start md:iv-justify-center',
        )}
      >
        {promoType === 'appPromoAI' && (
          <div className="iv-mr-15 iv-mt-15 iv-p-[7px] iv-bg-white-90 iv-rounded-lg md:iv-hidden sm:iv-hidden">
            <ImageComponent
              elementype="native"
              alt="App download"
              src={
                'https://web-assets.invideo.io/common/stage/app-promo/qr-banner.svg'
              }
              width={110}
              height={110}
              loading="lazy"
            />
          </div>
        )}
        <div
          className={ivclass(
            'iv-flex',
            'sm:iv-flex-row md:iv-flex-row lg:iv-flex-col xl:iv-flex-col',
            'sm:iv-gap-10 md:iv-gap-10',
            'iv-flex-wrap md:iv-justify-center',
          )}
        >
          {promoType !== 'appPromoAI'
            ? Object.keys(GlobalConfig.APP_PROMO_LINKS.desktop).map(os => {
                const { transparentImageURL, url } =
                  GlobalConfig.APP_PROMO_LINKS.desktop[os as OSType];

                return (
                  <AppDownloadCTAs
                    key={os}
                    hideAppDownloadCTA={
                      isNotDesktop && OS !== os ? 'iv-hidden' : ''
                    }
                    imageURL={transparentImageURL}
                    downloadLink={promoType !== 'appPromoAI' ? url : ''}
                  />
                );
              })
            : Object.keys(GlobalConfig.APP_AI_PROMO_LINKS.desktop).map(os => {
                const { transparentImageURL, url } = isMobile
                  ? GlobalConfig.APP_AI_PROMO_LINKS.mobile[os as OSType]
                  : GlobalConfig.APP_AI_PROMO_LINKS.desktop[os as OSType];

                return (
                  <AppDownloadCTAs
                    key={os}
                    hideAppDownloadCTA={''}
                    imageURL={transparentImageURL}
                    downloadLink={url}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default Details;
