import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import Title from '@components/app-promo/Title';
import Details from '@components/app-promo/Details';
import { HOMEPAGE } from '../../constants/homepage';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import useTranslation from 'next-translate/useTranslation';

interface AppPromoAIProps {
  os: string;
  isNotDesktop: boolean;
  promoType?: string;
  isPreview?: boolean;
}

const AppPromoAI = (props: AppPromoAIProps) => {
  const { os, isNotDesktop, promoType = 'appPromo', isPreview } = props;
  const { mobileImage, previewMobileImage } = HOMEPAGE.appPromoAI;
  const { t } = useTranslation('home');

  return (
    <section
      className={ivclass(
        'xl:iv-mb-[-3px] lg:iv-mb-[-3px]',
        'xl:iv-pb-150 lg:iv-pb-150',
        'xl:iv-px-20 lg:iv-px-40 md:iv-px-25 sm:iv-px-20',
        'xl:iv-pt-200 iv-pt-150 sm:iv-pt-100',
      )}
    >
      <div
        className={ivclass(
          'xl:iv-mb-[220px] lg:iv-mb-[180px] md:iv-mb-200 sm:iv-mb-[130px]',
        )}
      >
        <div
          className={ivclass(
            'iv-flex iv-flex-col iv-justify-center iv-items-center',
          )}
        >
          <Title promoType={promoType} />
        </div>
      </div>
      <div
        className={ivclass(
          'iv-gradient-blue xl:iv-rounded-30 iv-rounded-25 iv-min-h-[530px]',
        )}
      >
        <div
          className={ivclass(
            'iv-grid',
            'iv-grid-cols-2 md:iv-grid-cols-1 sm:iv-grid-cols-1',
            'iv-min-h-[530px]',
          )}
        >
          <div
            className={ivclass(
              'md:iv-px-100',
              'iv-place-self-center',
              'xl:iv-px-30 lg:iv-pl-60 md:iv-px-30',
              'xl:iv-pt-[56px] lg:iv-pt-[56px] md:iv-pt-[100px] sm:iv-pt-[46px]',
            )}
          >
            <div
              className={ivclass(
                'iv-text-grey-0',
                'iv-text-heading-4xl lg:iv-text-heading-2xl sm:iv-text-heading-s',
                'iv-m-heading-4xl lg:iv-m-heading-2xl sm:iv-m-heading-s',
                'iv-font-extrabold',
                'iv-pb-5',
                'md:iv-text-center sm:iv-text-center',
              )}
            >
              {t(`${promoType}.cardHeading`)}
            </div>
            <div
              className={ivclass(
                'iv-text-grey-0',
                'iv-text-heading-4xl lg:iv-text-heading-2xl sm:iv-text-heading-s',
                'iv-m-heading-4xl lg:iv-m-heading-2xl sm:iv-m-heading-s',
                'iv-font-extrabold',
                'md:iv-text-top sm:iv-text-center',
              )}
            >
              {t(`${promoType}.cardHeading2`)}
            </div>
            <Details
              os={os}
              isNotDesktop={isNotDesktop}
              promoType={promoType}
            />
          </div>
          <div className={ivclass('iv-relative')}>
            <div
              className={ivclass(
                'xl:iv-absolute lg:iv-absolute',
                'xl:iv-top-[-68px] lg:iv-top-[-68px] lg:iv-left-0 sm:iv-top-0',
                'iv-w-full',
                'xl:iv-mr-[117px] lg:iv-mr-[80px]',
                'md:iv-mx-auto sm:iv-mx-auto',
                'iv-max-w-[563px] sm:iv-max-w-[350px]',
              )}
            >
              <ImageComponent
                elementype="next"
                src={isPreview ? previewMobileImage : mobileImage}
                alt="InVideo Logo"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
                sizes="100vw"
                width={421}
                height={852}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppPromoAI;
