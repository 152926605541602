import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import ImageComponent from '@components/common/ImageComponent/ImageComponent';

interface DownloadsLinksProps {
  hideAppDownloadCTA: string;
  imageURL: string;
  downloadLink: string;
}

const AppDownloadCTAs = (props: DownloadsLinksProps) => {
  const { hideAppDownloadCTA, imageURL, downloadLink } = props;

  return downloadLink ? (
    <a
      className={ivclass(
        'iv-mt-15 sm:iv-mt-6',
        'xl:iv-mr-25 lg:iv-mr-20 md:iv-mx-[12px]',
        'md:iv-my-[14px]',
        'sm:iv-mx-auto',
        'sm:iv-mb-[45px]',
        'sm:iv-w-[120px]',
        hideAppDownloadCTA,
      )}
      target="_blank"
      rel="noreferrer"
      href={downloadLink}
    >
      <ImageComponent
        elementype="native"
        alt="App download"
        // loader={CustomImageLoader}
        src={imageURL}
        width={160}
        height={54}
        loading="lazy"
      />
    </a>
  ) : (
    <span
      className={ivclass(
        'iv-mt-15 sm:iv-mt-6',
        'xl:iv-mr-25 lg:iv-mr-20 md:iv-mx-[12px]',
        'md:iv-my-[14px]',
        'sm:iv-mx-auto',
        'sm:iv-mb-[45px]',
        'sm:iv-w-[120px]',
        'iv-opacity-30',
      )}
    >
      <ImageComponent
        elementype="native"
        alt="App download"
        // loader={CustomImageLoader}
        src={imageURL}
        width={160}
        height={54}
        loading="lazy"
      />
    </span>
  );
};

export default AppDownloadCTAs;
